


































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaNewPrelab5Q2',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        procedureAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'Explain in 3 sentences or less how to experimentally determine the partial order of a reactant in a reaction with two reactants. (hint: what can or should we do with the reactant whose partial order we are not trying to find).',
          fr: 'Expliquez, en 3 phrases ou moins, comment déterminer de façon expérimentale l’ordre partiel d’un réactif dans une réaction avec deux réactifs. (indice : qu’est-ce qu’on peut ou qu’est-ce qu’on doit faire avec l’autre réactif (de lequel on ne cherche pas l’ordre partiel.))',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
